<template>
  <div class="ui grid container subscribe">
    <div class="ui mini modal subscribed">

      <div class="header modalradiustop">
        Thank you for subscribing to TayoCash!
      </div>
      <div class="image content">

        <div class="description">
          Stay tuned for exciting updates!
        </div>
      </div>
      <div class="actions modalradiusbottom">
        <a class="ui ok violet formodal" @click="subscribedClose">Ok</a>
      </div>
    </div>

    <div class="div-1">
      <h1>Enter your email below to sign up for our newsletter
        and receive exciting and important <b> TayoCash updates!</b></h1>
        <div class="email-subs">
          <form class="ui form stackable">
            <div class="field radius">
              <div class="ui input">
                <input v-model="formsubscribed.subscribedEmail" type="email" placeholder="Enter email address">
                <span class="errorEmailSubcribed">{{ subcribedErrorMessage }}</span>
              </div>
            </div>
          </form>

          <a class="yellow" @click="subscribed">Subscribe</a>
          <!--
          <img src="@/assets/dpo_dps.png" alt="Tayocash" style="width: 400px; height: 500px; position: absolute; right: 50px; margin-top: -100px;" />
            
          <img src="@/assets/dpo_dps.png" alt="Tayocash" style="width: 300px; height: 400px;" />

          -->
          <a href="https://npcregistration.privacy.gov.ph/certificate/organizationRegistration/6404bcbd0d4cc62ee623ab58" target="_blank">
            <img src="@/assets/dpo_dps.png" alt="Tayocash" class="img-dpodps" />
          </a>
          
           
        </div>
        
      <div class="ui grid four column row">
        <div class="column">
          <div class="links first">
            <router-link @click.native="removehash" :to="{ path: '/', hash: '#product'}" replace>
              PRODUCT
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/', hash: '#tycshapp'}" replace>
              TayoCash App
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/', hash: '#srvs'}" replace>
              Services
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/', hash: '#scrty'}">
              TayoCash Security
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/', hash: '#dwnldapp'}" replace>
              Download App
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/', hash: '#prtnrwus'}" replace>
              Partner with Us
            </router-link>
          </div>
        </div>
        <div class="column">
          <div class="links second">
            <router-link @click.native="removehash" :to="{ path: '/about', hash: '#habout'}" replace>
              ABOUT
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/about', hash: '#mssn'}" replace>
              Who We Are
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/about', hash: '#crvls'}" replace>
              Our Core Values
            </router-link>
          </div>
        </div>
        <div class="column">
          <div class="links third">
            <router-link @click.native="removehash" :to="{ path: '/member', hash: '#hmmember'}" replace>
              MEMBER
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/member', hash: '#features'}" replace>
              Features
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/member', hash: '#acctyps'}" replace>
               Account Types
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/member', hash: '#bcmmber'}" replace>
              How to be a Member
            </router-link>
          </div>
        </div>
        <div class="column">
          <div class="links forth">
            <router-link @click.native="removehash" :to="{ path: '/partner', hash: '#hpartner'}" replace>
              PARTNER
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/partner', hash: '#srvcof'}" replace>
              Services Offered
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/partner', hash: '#bntsprtnr'}" replace>
              Benefits of a Partner
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/partner', hash: '#hwtbprtnr'}" replace>
              How to be a Partner
            </router-link>
            <router-link @click.native="removehash" :to="{ path: '/partner', hash: '#cntctus'}" replace>
              Contact Us
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'subscribe',
  data() {
    return {
      formsubscribed: {
        subscribedEmail: ""
      },
      subcribedErrorMessage: "",
     };
  },
  methods: {
    removehash() {
      this.$nextTick(() => {
        setTimeout(function() {
          window.location.hash = '_'
          history.replaceState(null, null, ' ');
        }, 50)
      })
    },
    subscribed () {
      this.subcribedErrorMessage = ""
      if (!this.formsubscribed.subscribedEmail) {
        this.subcribedErrorMessage = 'Email required.';
      } else if (!this.validEmail(this.formsubscribed.subscribedEmail)) {
        this.subcribedErrorMessage = 'Valid email required.';
      } else {
        this.$analytics.logEvent('subscribed', { email: this.formsubscribed.subscribedEmail});

        window.$('.mini.modal.subscribed')
        .modal({
          inverted: true
        })
        .modal('show');
      }
    },

    subscribedClose(){
      this.formsubscribed.subscribedEmail = "";
    },

    openInNewTab(url){
      window.open(url, '_blank').focus();
    },
    
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang='scss'>

.errorEmailSubcribed {
  color: red;
  font-size: .8em;
  font-weight: 400;
  position: absolute;
  margin-top: 34px;
  margin-left: 14px;
}

.modalradiustop {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom: none !important;
  color: #200f8c !important;
  text-align: center;
}

.modalradiusbottom {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top: none !important;
  background: #ffffff !important;
}

.ui.mini.modal.subscribed {
  border-radius: 20px;
}

.ui.modal>.content>.description {
  text-align: center;
}

.violet.formodal {
  padding-top: 10px;
  margin-left: 114px;
  width: 100px
}
</style>
